html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  box-sizing: border-box;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
  background-color: #f8f8f8;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
html {
  font-size: 62.5%;
}

.ReactCollapse--collapse {
  transition: height 300ms ease-out;
}

.ant-select-disabled {
  color: black !important;
}
.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  color: black !important;
  cursor: auto;
  margin-left: -12px;
}
.disabled-no-margin > div {
  margin-left: 0px !important;
}

.ant-picker-input > input[disabled] {
  color: black !important;
  cursor: auto;
  margin-left: -12px;
}

.ant-input[disabled] {
  color: black !important;
  cursor: auto;
}

.ant-steps-item-process .ant-steps-item-icon {
  background: #bdd000 !important;
  border-color: #bdd000 !important;
}

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: #bdd000 !important;
}

.ant-steps-item-finish .ant-steps-item-icon {
  border-color: #bdd000 !important;
}

.taxesTable .ant-table-expanded-row td {
  padding: 0;
}

.taxesTable .ant-table-row {
  background-color: #d0d0d0 !important;
}
.taxesTable .ant-table-row:hover {
  background-color: #d0d0d0 !important;
}

/* //TODO Some concept styles for button in kid/financial navbar*/
.custom-button-hover {
  background-color: #fff !important;
  border-color: #d9d9d9 !important;
  color: #000 !important;
}

.custom-button-hover:hover {
  background-color: #bdd000 !important;
  border-color: #d9d9d9 !important;
  color: #fff !important;
}

.custom-ant-menu-item a:hover {
  color: #fff !important;
}

.custom-ant-menu-item::after {
  border-bottom: 2px solid transparent !important;
}
